import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from '../components/Loading';
// Vistas Front end
const Login = lazy(() => import('../views/Login'));
const Home = lazy(() => import('../views/Home'));

// Módulo 1
const PreevaluacionM1 = lazy(() => import('../views/Modulo1/Preevaluacion'));
const Modulo1 = lazy(() => import('../views/Modulo1'));
const Video = lazy(() => import('../views/Modulo1/Video'));
const Video2 = lazy(() => import('../views/Modulo1/Video2'));
const Video3 = lazy(() => import('../views/Modulo1/Video3'));
const Elearning = lazy(() => import('../views/Modulo1/Elearning'));
const EvaluacionM1 = lazy(() => import('../views/Modulo1/Evaluacion'));
const CertificadoM1 = lazy(() => import('../views/Modulo1/CertificadoM1'));

// Módulo 2
const Modulo2 = lazy(() => import('../views/Modulo2'));
const VideoM2 = lazy(() => import('../views/Modulo2/Video'));
const EvaluacionM2 = lazy(() => import('../views/Modulo2/Evaluacion'));
const ElearningM2 = lazy(() => import('../views/Modulo2/Elearning'));
const CertificadoM2 = lazy(() => import('../views/Modulo2/CertificadoM2'));

// Vistas Back end
const LoginAdministrador = lazy(() => import("../views/Adminstration/Login"));
const HomeAdministrador = lazy(() => import("../views/Adminstration/Home"));
const Usuarios = lazy(() => import('../views/Adminstration/Usuarios'));
const UsuariosCrear = lazy(() => import('../views/Adminstration/Usuarios/Crear'));
const UsuariosActualizar = lazy(() => import('../views/Adminstration/Usuarios/Actualizar'));

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/home" element={<Home />} /> */}

          {/* Modulo1 */}
          <Route exact path="/modulo-uno" element={<Modulo1 />} />
          <Route exact path="/modulo-uno/preevaluacion-modulo-uno" element={<PreevaluacionM1 />} />
          <Route exact path="/modulo-uno/seguridad-un-trabajo-en-equipo" element={<Video />} />
          <Route exact path="/modulo-uno/fundamentos-feedback-bbs" element={<Video2 />} />
          <Route exact path="/modulo-uno/la-retro-en-la-practica" element={<Video3 />} />
          <Route exact path="/modulo-uno/importancia-del-liderazgo-seguro" element={<Elearning />} />
          <Route exact path="/modulo-uno/evaluacion-modulo-uno" element={<EvaluacionM1 />} />
          <Route exact path="/modulo-uno/:id/descargar-certificado" element={<CertificadoM1 />} />

          {/* Modulo2 */}
          <Route exact path="/modulo-dos" element={<Modulo2 />} />
          <Route exact path="/modulo-dos/bienvenida-al-safety-leadership" element={<VideoM2 />} />
          <Route exact path="/modulo-dos/importancia-del-liderazgo-seguro" element={<ElearningM2 />} />
          <Route exact path="/modulo-dos/evaluacion-modulo-dos" element={<EvaluacionM2 />} />
          <Route exact path="/modulo-dos/:id/descargar-certificado" element={<CertificadoM2 />} />

          {/* Back end⁄ */}
          <Route exact path="/admin/login" element={<LoginAdministrador />} />
          <Route exact path="/admin/dashboard" element={<HomeAdministrador />} />
          <Route exact path="/admin/usuarios" element={<Usuarios />} />
          <Route exact path="/admin/usuarios/crear" element={<UsuariosCrear />} />
          <Route exact path="/admin/usuarios/:id/actualizar" element={<UsuariosActualizar />} />

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
